import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { FooterComponent } from './footer/footer.component';
import { FormularioComponent } from './formulario/formulario.component';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { CuentaCreadaComponent } from './cuenta-creada/cuenta-creada.component';
import { AspiranteService } from './services/aspirante.service';

const routes: Routes = [
  {path:'', component:InicioComponent, pathMatch:'full'},
  {path:'formulario',component:FormularioComponent,pathMatch:'full'},
  {path:'perfilCreado', component:CuentaCreadaComponent,pathMatch:'full'}
];

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    FooterComponent,
    FormularioComponent,
    EncabezadoComponent,
    CuentaCreadaComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule
  ],
  providers: [
    InicioComponent,
    FormularioComponent,
    AspiranteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
