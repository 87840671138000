import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Router } from '@angular/router';
import 'rxjs/Rx'

@Injectable()
export class AspiranteService {

  url: string;

  constructor(private http: HttpClient, private router: Router) {
  	this.url = "https://api.colegiosigloxxi.net";
  }

  revisarCedula(cedula: string) {

  	return this.http.get(`${this.url}/aspirant/${cedula}`);
  }

  registrarAspirante(aspirante: any) {

  	let formData: FormData = new FormData();

    formData.append("cedula", aspirante.cedula);
    formData.append("apellidos", aspirante.apellidos);
    formData.append("nombres", aspirante.nombres);
    formData.append("telefono", aspirante.telefono);
    formData.append("sexo", aspirante.sexo);
    formData.append("grado", aspirante.grado);

    return this.http.post(`${this.url}/aspirant`, formData);
  }

}
