<div class="registro__bg">
    <div class="container__colegio">
        <div class="logotitle__box d-flex flex-nowrap">
            <img src="assets/static/img/logo-white.png" class="logotitle__img" alt="">
            <h2 class="logotitle__title">Registro de Inscripciones</h2>
        </div>
    </div>
    <div class="container__colegio d-flex justify-content-center">
        <form [formGroup]="aspirantForm" class="registro__boxbg" style="margin-bottom: 8em;">
            <h2 class="registro__title">Ingrese sus datos</h2>
            <div class="form-row mb-4">
                <div class="col-12 col-sm-2 registro__paddings">
                    <div class="form-control registro__selectbox mb-4">
                        <input class="form-control registro__selectskin mb-4" style="opacity: 1" value={{tipoDato}} [disabled]="true">
                    </div>
                </div>
                <div class="col-12 col-sm-10 registro__paddings">
                    <input class="form-control registro__inputs mb-4" value={{cedula}}    [disabled]="true" >
                </div>
                <div class="col-12 col-sm-6 registro__paddings" style="padding-right: initial;">
                    <input 
                        class="form-control registro__inputs mb-4"
                        formControlName="firstNames"
                        placeholder="Nombres"
                        [class.bordeError]="aspirantForm.get('firstNames').touched && aspirantForm.get('firstNames').errors"
                    >
                    <div *ngIf=" aspirantForm.get('firstNames').errors && aspirantForm.get('firstNames').touched" > 
                    <small *ngIf="aspirantForm.get('firstNames').hasError('required')">El campo es requerido</small> 
                    <small *ngIf="aspirantForm.get('firstNames').hasError('pattern')">Ingresa un nombre valido</small> 
                </div>
                </div>
                <div class="col-12 col-sm-6 registro__paddings">
                    <input
                        formControlName="lastNames"
                        class="form-control registro__inputs mb-4"
                        placeholder="Apellidos"
                        [class.bordeError]="aspirantForm.get('lastNames').touched && aspirantForm.get('lastNames').errors"
                    >
                    <div *ngIf=" aspirantForm.get('lastNames').errors && aspirantForm.get('lastNames').touched" > 
                    <small *ngIf="aspirantForm.get('lastNames').hasError('required')">El campo es requerido</small>
                    <small *ngIf="aspirantForm.get('lastNames').hasError('pattern')">Ingresa un apellido valido</small> 
                 </div>
                </div>
                <div class="col-12 col-sm-6 registro__paddings" style="padding-right: initial;">
                    <input
                        formControlName="celular"
                        class="form-control registro__inputs mb-4"
                        placeholder="Teléfono"
                        [class.bordeError]="aspirantForm.get('celular').touched && aspirantForm.get('celular').errors"
                    >

                <div *ngIf=" aspirantForm.get('celular').errors && aspirantForm.get('celular').touched"  > 
            <small *ngIf="aspirantForm.get('celular').hasError('required')">El campo es requerido</small>
            <small *ngIf="aspirantForm.get('celular').hasError('pattern')">Ingresa un numero de telefono valido</small> 
                </div> 
                </div>
                <div class="col-12 col-sm-3 registro__paddings" style="padding-left: 12px;padding-right: initial;">
                    <select  formControlName="genero" class="form-control registro__inputs_select mb-4" 
                        [class.bordeError]="aspirantForm.get('genero').touched && aspirantForm.get('genero').errors" >
                        <option value="" disabled="true" selected="selected">Género</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                    </select>
                    <div *ngIf=" aspirantForm.get('genero').errors && aspirantForm.get('genero').touched" > 
                    <small *ngIf="aspirantForm.get('genero').hasError('required')">El campo es requerido</small> 
                </div>
                </div>
                <div class="col-12 col-sm-3 registro__paddings" 
                     style="padding-left: 7px;">
                    <select formControlName="curso" class="form-control registro__inputs_select mb-4" [class.bordeError]="aspirantForm.get('curso').touched && aspirantForm.get('curso').errors" >
                        <option value="" disabled="true" selected="selected">Cursar</option>
                        <option value="1">1 periodo</option>
                        <option value="2">2 periodo</option>
                        <option value="3">3 periodo</option>
                        <option value="4">4 periodo</option>
                        <option value="5">5 periodo</option>
                        <option value="6">6 periodo</option>
                    </select>
                    <div *ngIf=" aspirantForm.get('curso').errors && aspirantForm.get('curso').touched" > 
                    <small *ngIf="aspirantForm.get('curso').hasError('required')">Es requerido</small> 
                </div>

                </div>
                <div class="d-flex flex-sm-nowrap justify-content-between w-100 registro__paddings">
                    <button class="registro__btn_atras m-0" (click)="atras()" type="button">Ir atras</button>
                    <button class="registro__btn m-0" data-toggle="modal" data-target="#exampleModalCenter" type="button" [disabled]="aspirantForm.invalid" >Registrarse</button>
                </div>
            </div>
        </form>
    </div>
    <div class="container__colegio">
        <h6 class="registro__footer">Copyright © 2020 <a href="https://colegiosigloxxi.net">Colegio XXI.</a></h6>
    </div>
</div>



<!--modal-->
<div class="modal fade modal__bg" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal__size" role="document">
        <div class="modal-content modal__box">
            <div class="modal-header border-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex justify-content-center flex-column">
                <div class="mx-auto">
                    <img src="assets/static/img/advertencia.png" class="modal__img" alt="">
                </div>
                <div class="text-center">
                    <h4 class="modal__title">Advertencia</h4>
                    <p class="modal__text">Los datos ingresados seran utilizados para procesar la inscripciones.<br>¿Los datos suministros son correctos?</p>
                </div>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-between modal__boxbtn">
                <button type="button"  class="modal__btn_cancelar" data-dismiss="modal">CANCELAR</button>
                <button type="button" class="modal__btn_confirmar" (click)="onSubmit()" data-dismiss="modal">CONFIRMAR</button>
            </div>
        </div>
    </div>
</div>