<div class="confirmado__bg">
    <div class="container__colegio">
        <div class="logotitle__box d-flex flex-nowrap">
            <img src="assets/static/img/logo-white.png" class="logotitle__img" alt="">
            <h2 class="logotitle__title"></h2>
        </div>
    </div>
    <div class="container__colegio">
        <div class="d-flex justify-content-center confirmado__box">
            <div class="my-auto d-flex flex-column">
                <img src="assets/static/img/confirmado.png" class="confirmado__img mx-auto" alt="">
                <h5 class="confirmado__title">Registro procesado con exito</h5>
                <p class="confirmado__text">Presione ‘continuar’ para seguir con el proceso de inscripción</p>
                <button type="button" class="confirmado__btn mx-auto" onclick="window.location.href = 'https://pagos.colegiosigloxxi.net'">CONTINUAR</button>
                <p class="confirmado__text">Nota: su contraseña inicial es la cedula ingresada</p>
            </div>
        </div>
        <h6 class="confirmado__footer">Copyright © 2020 <a href="https://colegiosigloxxi.net">Colegio XXI.</a></h6>
    </div>
</div>