import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AspiranteService } from '../services/aspirante.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})

export class InicioComponent implements OnInit {

  /**
   * Esta la peticion en proceso
   *
   * @property {boolean}
   */
  peticionEnProceso: boolean;

  formCedula = new FormGroup({
    tipoDocumento: new FormControl('V', [Validators.required]),
    cedula: new FormControl('', [Validators.required, Validators.maxLength(8), Validators.minLength(7) , Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)])
  });

  
  constructor(private aspiranteService: AspiranteService, private router: Router) {
    this.peticionEnProceso = false;

    // Limpiar datos del Storage
    localStorage.removeItem('tipoDato');
    localStorage.removeItem('cedula');
  }

  ngOnInit(): void {}

  /**
   * Evaluar cedula ingresada
   *
   * @return {void}
   */
  verificarExistenciaDeCedula() {

    // Formatear cedula
    let cedula = this.formCedula.get('tipoDocumento').value + '-' + this.formCedula.get('cedula').value;
    this.buscarAspirante(cedula);
  }

  /**
   * Consultar existencia de la cedula en API
   *
   * @param {string} cedula
   * @return {bool}
   */
  buscarAspirante(cedula) {

    this.peticionEnProceso = true;

    this.aspiranteService.revisarCedula(cedula).subscribe((res: any) => {

      // Verificar que existe el aspirante
      if (res.exist) {
        this.confirmarRegistroEncontrado({cedula, nombres: res.names});
      } else {
        this.confirmarInscripcion(cedula);
      }

      this.peticionEnProceso = false;
    },
    err => {
      console.log(err);
      this.peticionEnProceso = false;
      alert('Verifique su conexión');
    });
  }

  /**
   * Confirmacion del resultado por parte del usuario
   *
   * @param {object} aspirante
   * @return {void}
   */
  confirmarRegistroEncontrado(aspirante) {

    Swal.fire({
      title: 'Confirmación',
      text: `La cédula ${aspirante.cedula} esta registrada como ${aspirante.nombres}, si es correcto presiona para continuar al portal de pagos.`,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      denyButtonText: 'Cancelar',
      showDenyButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "https://pagos.colegiosigloxxi.net";
      }
    })
  }

  /**
   * Confirmacion continuar proceso de inscripcion
   *
   * @param {string} cedula
   * @return {void}
   */
  confirmarInscripcion(cedula) {

    Swal.fire({
      title: 'Confirmación',
      text: `La cédula ${cedula} no esta registrada como aspirante, ¿Desea realizar la pre-inscripción?.`,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      denyButtonText: 'Cancelar',
      showDenyButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.guardarDatos()
        this.router.navigateByUrl('/formulario');

      }
    })
  }

  /**
   * Almacenar datos para la siguiente vista
   *
   * @return {void}
   */
  guardarDatos() {
    localStorage.setItem('tipoDato',this.formCedula.get('tipoDocumento').value);
    localStorage.setItem('cedula',this.formCedula.get('cedula').value);
  }

}