import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { AspiranteService } from '../services/aspirante.service';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent implements OnInit {

    /**
     * Esta la peticion en proceso
     *
     * @property {boolean}
     */
    peticionEnProceso: boolean;

    tipoDato:any= localStorage.tipoDato;
    cedula:any= localStorage.cedula;

    aspirantForm = new FormGroup({
      lastNames: new FormControl('',[Validators.required,Validators.maxLength(50),Validators.pattern(/^[ñA-Za-z _]*[ñA-Za-z][ñA-Za-z _]*$/)]),
      firstNames: new FormControl('',[Validators.required,Validators.maxLength(50),Validators.pattern(/^[ñA-Za-z _]*[ñA-Za-z][ñA-Za-z _]*$/)]),
      celular: new FormControl('',[Validators.required,Validators.pattern(/^([0]{1})([4]{1})([1-2]{1})([2,4,6]{1})([0-9]{7})*$/)]),
      genero: new FormControl('',[Validators.required]),
      curso: new FormControl('',[Validators.required])
 });
  
  constructor(private aspiranteService: AspiranteService, private router: Router) {
    this.peticionEnProceso = false;
  }
  

  atras(){
    this.router.navigateByUrl('/');
  }
  
  ngOnInit(): void { 
    
    }

    

  onSubmit() {
    var perfil = {
      cedula:this.tipoDato+'-'+this.cedula,
      apellidos:this.aspirantForm.get('lastNames').value.toUpperCase(),
      nombres:this.aspirantForm.get('firstNames').value.toUpperCase(),
      telefono:this.aspirantForm.get('celular').value,
      sexo:this.aspirantForm.get('genero').value,
      grado:this.aspirantForm.get('curso').value
    }

  this.peticionEnProceso = true;
  
  this.aspiranteService.registrarAspirante(perfil).subscribe((res: any) => {
    
      console.log(res);
      this.peticionEnProceso = false;

      // Limpiar datos del Storage
      localStorage.removeItem('tipoDato');
      localStorage.removeItem('cedula');

      this.router.navigateByUrl('/perfilCreado');
    },
    err => {
      console.log(err)
      this.peticionEnProceso = false;
      alert('Verifique su conexión');
    });
  }

}