<div class="ingresar__bg">
  <div class="container__colegio">
    <div class="logotitle__box d-flex flex-nowrap">
      <img src="assets/static/img/logo-white.png" class="logotitle__img" alt="">
      <h2 class="logotitle__title" style="visibility: hidden">Ingresa tu documento</h2>
    </div>
  </div>
  <div class="container__colegio d-flex justify-content-center">
    <form [formGroup]="formCedula" class="ingresar__boxbg" style="width: 500px">
      <h2 class="ingresar__title">Ingrese su cédula</h2>
      <div class="form-row mb-4">
        <div class="col-12 col-sm-3 ingresar__paddings">
          <div class="form-control ingresar__selectbox mb-4">
            <select class="form-control ingresar__selectskin mb-4" style="opacity: 1" formControlName="tipoDocumento">
              <option value="V">V</option>
              <option value="E">E</option>
              <option value="P">P</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-9 ingresar__paddings">
          <input
            type="text"
            class="form-control ingresar__inputs mb-4"
            formControlName="cedula"
            placeholder="Cédula"
            style="margin-bottom: initial !important;"
            [class.bordeError]="formCedula.get('cedula').touched && formCedula.get('cedula').errors"
          >
          <div 
            *ngIf=" formCedula.get('cedula').errors && formCedula.get('cedula').touched"
            style="margin-bottom: 1em; font-size: 1em;"
          >
            <small *ngIf="formCedula.get('cedula').hasError('required')"> El campo es requerido <br> </small>
            <small *ngIf="formCedula.get('cedula').hasError('minlength')"> Minimo son 7 numeros <br> </small>
            <small *ngIf="formCedula.get('cedula').hasError('maxlength')"> Maximo son 8 numeros <br> </small>
            <small *ngIf="formCedula.get('cedula').hasError('pattern')"> Solo ingresa numeros   <br> </small>
        </div>
        </div>
        <div class="d-flex flex-sm-nowrap justify-content-between w-100 ingresar__paddings">
          <button 
            class="ingresar__btn m-0"
            type="button"
            (click)="verificarExistenciaDeCedula()"
            [disabled]="formCedula.invalid || peticionEnProceso"
          >
            Consultar
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="container__colegio ingresar__footerbox">
    <h6 class="ingresar__footer">Copyright © 2020 <a href="https://colegiosigloxxi.net">Colegio XXI.</a></h6>
  </div>
</div>